import Link from 'next/link'
import type { I18n, TFunction } from "next-i18next"
import React, { useState } from "react"
import { useRouter } from 'next/router'


const LanguageSelector = ({t, i18n}: {t: TFunction, i18n: I18n}) => {

    const languages = [['en-US', t('i18n.name.en_US', 'English')], ['es-ES', t('i18n.name.es_ES', 'Spanish')]]
    const router = useRouter()
    const [hoverDisplayVisible, setHoverDisplayVisible] = useState(false)
    const hoverDisplay = hoverDisplayVisible ? "block" : "none"
    const hoverDisplayVisibleClass = hoverDisplayVisible ? "visible" : "hidden"

    return (
        <div className="language-selector-wrapper">
            <div className={`language-selector ${hoverDisplayVisibleClass}`}
                style={{
                    //border: "1px solid red",
                    cursor: "pointer",
                }}
                onClick={() => setHoverDisplayVisible(!hoverDisplayVisible)}
            >
                {t('i18n.language', 'Language')}
            </div>
            <div className={`language-selector-menu-hover ${hoverDisplayVisibleClass}`} style={{
                display: hoverDisplay,
                position: "absolute",
                bottom: "-89px",
                // border: "0.1px solid black",
                boxShadow: "1px 6px 6px 0 rgba(51,51,51,.2)",
                backgroundColor: "white",
                marginLeft: "-15px",
                padding: "30px 30px 80px 30px",
            }}>
                <ul style={{ display: "block" }}>
                    {languages.map(([locale, name], key) => {
                        const isActive = locale === i18n.language
                        return (<li key={key} style={{
                            display: isActive ? "list-item" : "block",
                            marginBottom: "10px",
                        }}>
                            {isActive
                            ? <Link key={key} href={router.route} locale={locale}><a onClick={(e) => e.preventDefault()} className="language-active">{name}</a></Link>
                            : <Link key={key} href={router.route} locale={locale} scroll={false}><a>{name}</a></Link>
                            }
                        </li>)
                    })}
                </ul>
            </div>
        </div>
    )
}


export default LanguageSelector
