import { useRouter } from 'next/router'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { getActualLocales } from './i18n'
import { AlternateLink, useAlternateLinks, useCanonicalLink } from '../hooks/i18n'


const BrandedContainer = ({children, title, description}: {children: JSX.Element, title?: string, description?: string}) => {
    const router = useRouter()
    const { t } = useTranslation()
    const fullUrl = "https://" + `iuvia.io/${router.basePath}${router.locale}${router.asPath}`.replace(/\/+/, '/')
    const unlocalizedUrl = "https://" + `iuvia.io/${router.basePath}${router.asPath}`.replace(/\/+/, '/')

    const canonicalLink = useCanonicalLink()
    const alternateLinks = useAlternateLinks()

    return (
        <>
            <Head>
                <script type="application/ld+json">{
                    JSON.stringify(
                        [{
                            "@context":"http:\/\/schema.org",
                            "@type":"Corporation",
                            "logo":"https:\/\/iuvia.io\/images\/logos\/iuvia.svg",
                            "name":"IUVIA",
                            "url":"https:\/\/iuvia.io",
                            "image":"https:\/\/iuvia.io\/images\/landing-preview.png",
                            "contactPoint":{"@type":"ContactPoint","contactType":"Customer Support","email":"info@iuvia.io"}
                        },
                        {
                            "@context":"http:\/\/schema.org",
                            "@type":"Website",
                            "name":"IUVIA - Own your data, be free",
                            "url":"https:\/\/iuvia.io",
                            "keywords":"privacy-by-design, cloud, open source, my cloud, home cloud, data privacy, privacy box, home cloud, secure storage, secure email, private email, private storage"
                        },
                        {
                            "@context":"http:\/\/schema.org","@type":"Product",
                            "name":"IUVIA R1",
                            "description":"IUVIA R1 is a privacy-by-design device that allows anyone to own their data, host their cloud services at home and enjoy all the operative benefits of the cloud without its privacy issues. Own your data, be free.",
                            "image":["https:\/\/iuvia.io\/images\/background\/IUVIA_01.jpg","https:\/\/iuvia.io\/images\/background\/IUVIA_02.jpg"]
                        }]
                    )
                }</script>
                <meta key="og:title" property="og:title" content={title} />
                {description ? <meta key="og:description" property="og:description" content={description} /> : null }
                <meta key="og:url" property="og:url" content={fullUrl} />
                <meta key="og:site_name" property="og:site_name" content={t('og.site_name', "IUVIA - Own your data, be free")} />
                <meta key="og:image" property="og:image" content="https://iuvia.io/images/landing-preview.jpg" />
                <meta key="twitter:title" property="twitter:title" content={title} />
                <meta key="twitter:image" property="twitter:image" content="https://iuvia.io/images/landing-preview.jpg" />
                <meta key="twitter:url" property="twitter:url" content={fullUrl} />
                <meta key="twitter:site" property="twitter:site" content="@iuvia_" />
                <meta key="twitter:card" property="twitter:card" content="summary_large_image" />
                <link key="favicon" rel="shortcut icon" href="/favicon.png" />
                
                {alternateLinks.map(({ href, hrefLang, key }: AlternateLink) => {
                    return <link rel="alternate" href={href} hrefLang={hrefLang} key={key} />
                })}
                {/*
                <link key="alt-lang-xdefault" rel="alternate" href={unlocalizedUrl} hrefLang="x-default" />
                {
                    getActualLocales(router.locales, router.defaultLocale)?.map((locale) => {
                        const localizedUrl = "https://" + `iuvia.io/${router.basePath}${locale}${router.asPath}`.replace(/\/+/, '/')
                        return (<link key={`alt-lang-${locale}`} rel="alternate" href={localizedUrl} hrefLang={locale} />)
                    })
                }
                */
                }
                <title>{title}</title>
            </Head>
            {children}
        </>
    )
}


export default BrandedContainer
