import Image from 'next/image'
import Link from 'next/link'
import Script from 'next/script'
import { useTranslation } from "react-i18next"


const MauticAnalytics = () => {
    return (
        <Script type="text/javascript" strategy="afterInteractive">{`
        (function(w,d,t,u,n,a,m){w['MauticTrackingObject']=n;
          w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)},a=d.createElement(t),
            m=d.getElementsByTagName(t)[0];a.async=1;a.src=u;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://mic.iuvia.io/mtc.js','mt');
        mt('send', 'pageview');
      `}</Script>
    )
}


const Footer = () => {
    const { t } = useTranslation()

    return (<footer className="page-footer">
        <div className="container">
            <div className="flex">
                <div className="fh5co-footer-logo">
                    <a href="#" className="navbar-brand"><img src="/images/logos/iuvia_130.png" /></a>
                    <ul className="social-icons-list">
                        <li><a title={t('blog.title')} href="https://blog.iuvia.io" target="_blank"><i className="icon-edit"></i></a></li>
                        <li><a title="Twitter" href="https://twitter.com/iuvia_" target="_blank"><i className="icon-twitter-square"></i></a></li>
                        <li><a title="Gitlab" href="https://gitlab.com/iuvia" target="_blank"><i className="icon-git-square"></i></a></li>
                        <li><a title="LinkedIn" href="https://www.linkedin.com/company/iuvia" target="_blank"><i className="icon-linkedin-square"></i></a></li>
                    </ul>
                </div>
                <div className="centering">
                    <h3>{t('footer.getInTouch.title')}</h3>
                    <ul>
                        <li><Link href="/privacy-policy" locale="es-ES"><a>{t('footer.getInTouch.privacy-policy')}</a></Link></li>
                        <li><a href="mailto:info@iuvia.io">{t('footer.getInTouch.email')}</a></li>
                    </ul>
                </div>
                <div className="ngi-funding">
                    This project has received funding from the European Union’s Horizon 2020 research and
                    innovation programme within the framework of the LEDGER Project funded under grant agreement
                    No 825268.
                    <div className="ngi-logos">
                        <img src="/images/logos/eu_logo.png" style={{height: "3em"}} />
                        <img src="/images/logos/ngi_ledger.png" style={{height: "3em"}} />
                    </div>
                </div>
            </div>
        </div>
        <MauticAnalytics />
    </footer>)
}

export default Footer

