
import { getClientBuildManifest } from 'next/dist/client/route-loader'
import { Rewrite } from 'next/dist/lib/load-custom-routes'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getActualLocale, getActualLocales, normalizeLocale } from '../components/i18n'

/**
 * Hook to get the localized URL from a standard non-localized Next.js URL.
 *
 * @param locale - The locale of the localized URL.
 * @param urlPath - The non-localized URL path (e.g. `/contact-us`).
 *
 * @returns The localized URL.
 */
export function useRewrites(): Rewrite[] {
    const [rewrites, setRewrites] = useState<Rewrite[]>([])

    useEffect(() => {
        getClientBuildManifest()
            .then((clientBuildManifest) => {
                // Next.js needs to add types https://github.com/vercel/next.js/blob/d130f63c416b21adc67dc3c755ad6e1a707e2cc8/packages/next/build/webpack/plugins/build-manifest-plugin.ts#L41
                setRewrites((clientBuildManifest.__rewrites as any).afterFiles)
            })
            .catch(console.error)
    }, [])

    return rewrites
}


/**
 * Get the localized URL path (without the locale prefix) from a standard non-localized Next.js URL path.
 *
 * Using the `Rewrite` objects, the `destination` is the standard Next.js "localized" URL (e.g. `/fr-ca/contact-us`) and
 * the `source` is the real localized URL (e.g. `/fr-ca/nous-joindre`).
 *
 * @param rewrites - An array of Next.js rewrite objects.
 * @param locale - The locale of the localized URL.
 * @param urlPath - A standard non-localized Next.js URL path without the locale prefix (e.g. `/contact-us`).
 *
 * @returns The localized URL.
 */
export function getLocalizedUrlPath(rewrites: Rewrite[], locale: string | undefined, urlPath: string): string {
    const normalizedUrlPath = normalizeUrlPath(urlPath)
    const destinationUrlPath = locale ? `/${locale}${normalizedUrlPath}` : ("" + normalizedUrlPath)
    const rewrite = rewrites.find(
        (rewrite) => rewrite.locale === false && rewrite.destination === destinationUrlPath
    )

    return rewrite ? rewrite.source : destinationUrlPath // Fallback with the original URL path when not found.
}

/**
* Normalize a URL.
*
* A normalized URL should: always start with a slash, never end with a slash and never contain double slashes.
*
* @param urlPath - The URl to normalize.
*
* @returns A normalized URL.
*/
export function normalizeUrlPath(urlPath?: string): string | undefined {
    // Remove double slashes just in case.
    let normalizedUrlPath = urlPath?.replace(/\/\//g, '/')

    // Make sure the base path starts with a slash.
    normalizedUrlPath = normalizedUrlPath?.startsWith('/')
        ? normalizedUrlPath
        : `/${normalizedUrlPath}`

    // Make sure the base path does not end with a slash.
    normalizedUrlPath = normalizedUrlPath?.endsWith('/')
        ? normalizedUrlPath.slice(0, -1)
        : normalizedUrlPath

    return normalizedUrlPath
}
/**
 * Canonical links tell search engines what is the URL of a page that should be indexed.
 */
export type CanonicalLink = {
    /** the canonical, fully-qualified URL of a page. */
    href: string
    /** The unique key to use in Next.js `<Head>`. */
    key: string
}


/**
 * Hook to get the localized URL from a standard non-localized Next.js URL.
 *
 * @param locale - The locale of the localized URL.
 * @param urlPath - The non-localized URL path (e.g. `/contact-us`).
 *
 * @returns The localized URL.
 */
export function useLocalizedUrl(locale: string, urlPath: string): string {
    const rewrites = useRewrites()
    return getLocalizedUrlPath(rewrites, locale, urlPath)
}

/**
 * Get the current environment's URL origin (protocol + domain).
 *
 * @returns The normalized (lowercase, without a trailing slash) URL origin used to generate fully-qualified URLs.
 */
export function getOrigin(): string {
    const origin = process.env.NEXT_PUBLIC_ORIGIN

    if (!origin) {
        throw new Error(
            'Please add NEXT_PUBLIC_ORIGIN in your current environment variable with a fully-qualified URL (protocol + domain)'
        )
    }

    try {
        // Only valid URLs can create a URL object without throwing errors.
        const originUrl = new URL(origin)

        // Make sure that the configured URL has a valid protocol.
        if (!['http', 'https'].includes(originUrl.protocol.slice(0, -1))) {
            throw new Error()
        }

        // Make sure that the configured URL does not include a path (Next.js' `basePath` should be used instead).
        if (originUrl.pathname !== '/') {
            throw new Error()
        }
    } catch (error) {
        throw new Error(
            'Please make sure that your NEXT_PUBLIC_ORIGIN environment variable is a fully-qualified URL (e.g. https://example.com) without any path'
        )
    }

    return (origin.endsWith('/') ? origin.slice(0, 1) : origin).toLowerCase()
}

/**
 * A hook to get the canonical link of your current page.
 *
 * A canonical link tell the search engines which page to index to avoid "page collisions". This is especially important
 * with Next.js as URLs casing is not always sensitive which could cause problems when indexing. URL queries are ignore by
 * design to avoid duplicate content penalties with search engines.
 *
 * @returns A canonical link object for the current page.
 */
export function useCanonicalLink(): CanonicalLink {
    const { basePath, pathname, defaultLocale, locale, locales, route } = useRouter()
    const rewrites = useRewrites()
    const origin = getOrigin()
    const normalizedBasePath = normalizeUrlPath(basePath)

    const actualLocale = getActualLocale(locale, defaultLocale, locales)
    const key = 'canonical-link'

    if (route === '/') {
        const localePrefix = normalizeUrlPath(actualLocale?.toLowerCase())
        const href = `${origin}${normalizedBasePath}${localePrefix}`
        return {
            href,
            key,
        }
    } else {
        const localizedUrlPath = getLocalizedUrlPath(rewrites, actualLocale, pathname)
        const href = `${origin}${normalizedBasePath}${localizedUrlPath}`
        return {
            href,
            key,
        }
    }
}

export type AlternateLink = {
    /** A fully-qualified URL of a page in a specific locale. */
    href: string
    /** The target locale, or `x-default` for pages that can dynamically resolve different locales. */
    hrefLang: string
    /** The unique key to use in Next.js `<Head>`. */
    key: string
}

/**
 * A hook to get the alternate links of your current page.
 *
 * Alternative links provide information to search engines that a given page is available in other languages. URL queries will
 * be added to alternate links regardless if they are valid or not, but they will be removed from canonical links to avoid
 * content duplication issues.
 *
 * @returns An array of alternate links objects for the current page.
 */
export function useAlternateLinks(): AlternateLink[] {
    const { basePath, asPath, defaultLocale, locales, locale } = useRouter()
    const rewrites = useRewrites()
    const origin = getOrigin()
    const [urlPath, asPathQuery] = asPath.split('?')
    const urlSearchParamsString = new URLSearchParams(asPathQuery).toString()
    const urlQuery = urlSearchParamsString ? `?${urlSearchParamsString}` : ''
    const normalizedBasePath = normalizeUrlPath(basePath)
    const actualLocale = getActualLocale(locale, defaultLocale, locales)

    const alternateLinks: AlternateLink[] = getActualLocales(locales, defaultLocale)?.map((locale) => {
        const localizedUrlPath = getLocalizedUrlPath(rewrites, locale, urlPath)
        const href = `${origin}${normalizedBasePath}${localizedUrlPath}${urlQuery}`
        const hrefLang = normalizeLocale(locale)
        const key = `alternate-link-${hrefLang}`

        return {
            href,
            hrefLang,
            key,
        }
    }) || []

    /**
     * `x-default` is meant to be used for pages that have "dynamic" locales. The only valid use case for most websites is
     * the home page where the language detection can happen. After this initial locale resolution, all other URLs should use
     * prefixes and the `x-default` link should not be required.
     */
    const localizedUrlPath = getLocalizedUrlPath(rewrites, undefined, urlPath)
    const href = `${origin}${normalizedBasePath}${localizedUrlPath}`

    const hrefLang = 'x-default'
    const key = `alternate-link-${hrefLang}`
    alternateLinks.push({
        href,
        hrefLang,
        key,
    })

    return alternateLinks
}
